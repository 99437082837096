<template>
  <div class="w-100 h-100 d-flex flex-column">
    <a-list
      id="trace-video-comments-list"
      :data-source="commentsList"
      item-layout="horizontal"
      size="small"
      :style="{ height: '40vh', overflow: 'auto' }"
      :loading="isFetchingVideoUrl"
    >
      <template #renderItem="{ item, index: commentIndex }">
        <a-list-item :id="'comment-' + commentIndex">
          <a-comment :author="item.user_id.username" :content="item.comment">
            <template v-if="isAssociateUserComment(item.user_id)" #actions>
              <delete-outlined
                :id="'delete-comment-btn-' + commentIndex"
                class="text-danger"
                @click="deleteComment(item)"
              />
            </template>
          </a-comment>
        </a-list-item>
      </template>
    </a-list>
    <a-comment>
      <template #content>
        <a-form-item :class="{ 'm-0': isMinimized }">
          <a-textarea
            id="label-new-comment-input"
            v-model:value="newComment"
            :rows="4"
            :max-length="400"
          />
        </a-form-item>
        <a-form-item :class="{ 'm-0': isMinimized }">
          <a-button
            id="add-comment-btn"
            html-type="submit"
            :loading="addingComment"
            type="primary"
            class="mt-1"
            :disabled="isFetchingVideoUrl || !newComment.trim().length"
            @click="handleAddNewComment"
          >
            Add Comment
          </a-button>
        </a-form-item>
      </template>
    </a-comment>
  </div>
</template>
<script>
import { DeleteOutlined } from '@ant-design/icons-vue';
import VideoService from '../../../../services/videos';
import { mapGetters } from 'vuex';

export default {
  components: {
    DeleteOutlined
  },
  props: {
    comments: { type: Array, default: () => [] },
    selectedVideo: { type: Object, default: () => ({}) },
    isMinimized: { type: Boolean, default: false },
    isFetchingVideoUrl: { type: Boolean, default: false }
  },
  emits: ['updateVideo'],
  setup() {
    const userId = localStorage.getItem('id');
    const userName = localStorage.getItem('name');
    return { userId, userName };
  },
  data() {
    return {
      addingComment: false,
      record: null,
      commentsList: [],
      newComment: ''
    };
  },

  computed: {
    ...mapGetters(['user', 'organization'])
  },

  watch: {
    selectedVideo(value) {
      if (value) this.record = value;
    },
    comments(list) {
      this.commentsList = list;
    },
  },

  beforeMount() {
    this.commentsList = this.comments;
    this.record = this.selectedVideo;
  },

  methods: {
    async handleAddNewComment() {
      if (!this.newComment.trim().length) {
        this.addingComment = false;
        this.newComment = '';
        return
      };
      this.addingComment = true;
      // add entity if not present
      if (!this.record.entity_id) {
        const res = await this.addNewEntity();
        const temp = { ...this.record };
        temp['entity_id'] = res.id;
        this.record = temp;
      }
      // create comment
      const comment_res = await this.createComment();
      // await VideoService.increaseCommentCount(this.record);
      comment_res['user_id'] = { id: comment_res.user_id, username: this.user };
      this.commentsList = [...this.commentsList, comment_res];
      // update task record
      const { entity_id, no_of_comments } = this.record;
      await this.updateTaskRecord(
        {
          entity_id: entity_id,
          no_of_comments: no_of_comments + 1,
          comments: this.commentsList
        },
        this.record
      );
      // if (!this.isMinimized) this.updateCommentCounts(1);
      this.updateCommentCounts(1);
      this.addingComment = false;
      this.newComment = '';
    },

    async addNewEntity() {
      const payload = {
        name: this.record.fileName,
        organization: this.organization
      };
      return await VideoService.createEntityForVideo(payload);
    },

    async createComment() {
      const payload = {
        comment: this.newComment,
        entity_id: this.record.entity_id,
        user_id: this.userId
      };
      return await VideoService.addNewComment(payload);
    },

    async updateTaskRecord(updatedData, videoToUpdate) {
      // update video item
      this.$emit('updateVideo', {
        videoName: videoToUpdate.fileName,
        updatedObj: { ...updatedData }
      });

      // delete updatedData?.comments;
      return await VideoService.updateTaskRecord(videoToUpdate.id, updatedData);
    },

    async deleteComment(comment) {
      const [error] = await VideoService.deleteComment(comment.id);
      if (error) {
        console.log({ error });
        return;
      }
      this.commentsList = this.commentsList.filter(t => t.id !== comment.id);
      // await VideoService.decreaseCommentCount(this.record);
      const count = this.record.no_of_comments - 1;
      // update task record
      await this.updateTaskRecord(
        { no_of_comments: count <= 0 ? 0 : count, comments: this.commentsList },
        this.record
      );
      // if (!this.isMinimized) this.updateCommentCounts(-1);
      this.updateCommentCounts(-1);
    },

    isAssociateUserComment(user_data) {
      return user_data.id == this.userId && user_data.username == this.userName
        ? true
        : false;
    },

    updateCommentCounts(increment) {
      const count = this.record.no_of_comments + increment;
      this.record = {
        ...this.record,
        no_of_comments: count <= 0 ? 0 : count
      };
    }
  }
};
</script>
<style>
.ant-comment .ant-comment-inner {
  padding: 4px !important;
}

.ant-comment-actions {
  margin-top: 6px;
}
</style>
